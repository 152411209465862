import * as Types from '../../actions/type'
const initialState = {
    loading: false,
    loaded: false,
    data: [],
    error: '',
    tripCost: [],
    addons: [],
    roomType: [],
    bookingInfo: [],
    surCharge: [],
    packageGroup: [],
    activeAgent: [],
    activeCustomer: []
}



export const packageDetails = (state = initialState, action) => {
    switch (action.type) {
        case Types.GET_PACKAGE_DETAILS:
            return { ...state, loading: true };
        case Types.GET_PACKAGE_TRIPCOST:
            return { ...state, loading: false, loaded: true, tripCost: action.payload.tripCosts }
        case Types.GET_PACKAGE_ADDONS:
            return { ...state, loading: false, loaded: true, addons: action.payload.data }
        case Types.GET_PACKAGE_BOOKINFO:
            return { ...state, loading: false, loaded: true, bookingInfo: action.payload.data }
        case Types.GET_PACKAGE_BOOKINFO_CLEAR:
            return { ...state, loading: false, loaded: true, bookingInfo: [] }
        case Types.GET_PACKAGE_ROOMTYPE:
            return { ...state, loading: false, loaded: true, roomType: action.payload.roomTypes || [] }
        case Types.GET_PACKAGE_SUCCESS:
            return { ...state, loading: false, loaded: true, data: action.payload }
        case Types.GET_PACKAGE_FAILURE:
            return { ...state, loading: false, loaded: false, error: action.payload }
        case Types.GET_PACKAGE_SURCHARGE:
            return { ...state, loading: false, loaded: true, surCharge: action.payload.surCharges || [] }
        case Types.GET_PACKAGE_GROUP:
            return { ...state, loading: false, loaded: true, packageGroup: action.payload.agentGroup }
        case Types.GET_ACTIVE_AGENT:
            return { ...state, loading: false, loaded: true, activeAgent: action.payload.users }
        case Types.GET_ACTIVE_CUSTOMER:
            return { ...state, loading: false, loaded: true, activeCustomer: action.payload }
        case Types.GET_ACTIVE_AGENT_CATEGORIES:
            return { ...state, loading: false, loaded: true, activeAgentCategory: action.payload }
        default:
            return state
    }
}



