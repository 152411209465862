export const portalData = {
    // Fax Details
    hasFax: true,
    faxNo: "60 89 788 080",
    
    // Whatsapp details
    hasWhatsApp: true,
    whatsAppNo: "60 10-932 6233",
    whatsAppNoHolderName: "Mataking Reef Resort",
    hasAltWhatsApp: false,
    altWhatsAppNo: "",
    altWhatsAppNoHolderName: "",
    hasAltWhatsApp1: false,
    altWhatsAppNo1: "",
    altWhatsAppNoHolderName1: "",
    hasAltWhatsApp2: false,
    altWhatsAppNo2: "",
    altWhatsAppNoHolderName2: false,
    
    // Phone number details
    hasPhoneNo: true,
    phoneNo: "+60128161665",
    hasAltPhoneNo: false,
    altPhoneNo: "",
    
    // Telephone number details
    hasTellNo: true,
    tellNo: "+60128161665",
    tellNoHolderName: "Wong Siao Ying @ Janice",
    hasAltTellNo: true,
    altTellNo: "+60198235298",
    altTellNoHolderName: "Chok Yen Moi @ Emily",
    hasAltTellNo2: false,
    altTellNo2: "",
    altTellNoHolderName2: "",
    
    // Portal information details
    portalNameXSpace: "Mataking Reef Resort",
    portalName: "Mataking Reef Resort",
    portalFullName: "Mataking Reef Resort",
    portalFullNameUppercase: "MATAKING REEF RESORT",
    portalShortAddress: "TB 2364, Sabah",
    portalAddress: "TB 2364, Leeka Light Industrial Estate, Jalan Apas, Batu 3, 91000 Tawau, Sabah, Malaysia",
    portalAddressFirstPart: "TB 2364, Leeka Light Industrial Estate, Jalan Apas, Batu 3,",
    portalAddressSecondPart: "91000 Tawau, Sabah, Malaysia",
    portalAddressDetails: "TB 2364, Leeka Light Industrial Estate, Jalan Apas, Batu 3, 91000 Tawau, Sabah, Malaysia",
    hasPortalRegNo: true,
    portalRegNo: "197901008498 (52785-U)",
    
    // Portal Bank Details
    portalBankName: "Public Bank Berhad",
    portalBankAddress: "TB 304 A & B, Block 34, Ground & 1st Floor, Fajar Complex, 91000 Tawau, Sabah",
    portalBankAccHolderName: "GTS Travel Service Sdn Bhd",
    portalBankAccNo: "3121746220",
    hasPortalBankSwiftCode: true,
    portalBankSwiftCode: "PBBEMYKL",
     
    portalEmail: "sales@mataking.com",
    portalTermAndCondUrl: process.env.REACT_APP_PORTAL_TERM_AND_COND_URL,   
    appName: "Island Resort Centralised Reservation Platform",

    // GST details
    hasGst: false,
    gstNo: true,
     
    // Website
    portalWebsite: process.env.REACT_APP_PORTAL_WEBSITE,
   
    // Travoxis Website
    travoxisWebsite: process.env.REACT_APP_TRAVOXIS_WEBSITE,
   
    // Facebook page link
    facebookMessengerLink: "https://www.facebook.com/MatakingIsland",
   
    // portal S3 base url
    s3AwsUrl: "",
   
    adminPortalUrl: process.env.REACT_APP_ADMIN_PORTAL_URL,
  
    // Portal Jetty names
    jettyOne: "Semporna Seafest Jetty",
    jettyTwo: "Mataking Reef Resort Jetty",
    jettyThree: "",
    jettyOneToTwoTime: "09:30 AM",
    jettyTwoToOneTime: "11:00 AM",
    portalAdminUrl: process.env.REACT_APP_ADMIN_PORTAL_URL,
    fromLocation: "",
}  