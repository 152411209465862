export const generateAppConfig = () => {
  let exportResult = {
    appRepoLocation: "https://sg-app.mataking.com",
    backendUrl: "http://localhost:3100",
    jsReportUrl: "http://localhost:5488",
    travelAgentBackendUrl: "http://localhost:3000",
    adminRepoLocation: "http://localhost:3000",
    bookingAppRepoLocation: "http://localhost:3001",
  };
  if (process.env.REACT_APP_PRODUCTION_MODE === "TEST") {
    exportResult.appRepoLocation = "https://sg-app.mataking.com";
    exportResult.backendUrl = "https://sg-api.mataking.com/admin";
    exportResult.jsReportUrl = "https://sg-report.mataking.com";
    exportResult.travelAgentBackendUrl = "https://sg-api.mataking.com/business";
    exportResult.adminRepoLocation = "https://sg-admin.mataking.com";
    exportResult.bookingAppRepoLocation = "https://sg-booking.mataking.com";
  }
  if (process.env.REACT_APP_PRODUCTION_MODE === "STAGE") {
    exportResult.appRepoLocation = "https://app.travoxis.com";
    exportResult.backendUrl = "https://admin-api.travoxis.com";
    exportResult.jsReportUrl = "https://report-test.mataking.com.my";
    exportResult.travelAgentBackendUrl = "https://business-api.travoxis.com";
    exportResult.adminRepoLocation = "https://admin.travoxis.com";
    exportResult.bookingAppRepoLocation = "https://booking.travoxis.com";
  }
  if (process.env.REACT_APP_PRODUCTION_MODE === "PROD") {
    exportResult.appRepoLocation = "https://app.mataking.com";
    exportResult.backendUrl = "https://api.mataking.com/admin";
    exportResult.jsReportUrl= "https://report.mataking.com";
    exportResult.travelAgentBackendUrl =  "https://api.mataking.com/business";
    exportResult.adminRepoLocation = "https://admin.mataking.com";
    exportResult.bookingAppRepoLocation = "https://booking.mataking.com";
  }
  return exportResult;
};
